import * as React from 'react';
import {
    Edit,
    TextInput,
    NumberInput,
    TabbedForm,
    useTranslate,
} from 'react-admin';
import { Box, Typography } from '@mui/material';
import SegmentsFormToolbar from './SegmentsFormToolbar';

const GeneralForm = (props?: any) => {
    const translate = useTranslate();
    const formDefaults = {
        multiple: true,
        method: 'post',
    };
    const sx = props?.hidden
        ? {
              display: 'none',
              tabindex: '-1',
          }
        : {};

    return (
        <Edit
            resource="settings"
            redirect={false}
            mutationMode="pessimistic"
            sx={sx}
        >
            <Box>
                <Typography variant="h4" padding={2}>
                    {translate('resources.settings.fieldGroups.general')}
                </Typography>
                <TabbedForm
                    defaultValues={formDefaults}
                    toolbar={<SegmentsFormToolbar />}
                    syncWithLocation={false}
                >
                    <TabbedForm.Tab label="resources.settings.fieldGroups.general_basic">
                        <TextInput
                            source="Logistics__default_to"
                            label="resources.settings.values.logistics__default_to"
                            helperText="resources.settings.values.logistics__default_to__helper"
                            fullWidth
                        />
                        <TextInput
                            source="Logistics__errors_to"
                            label="resources.settings.values.logistics__errors_to"
                            helperText="resources.settings.values.logistics__errors_to__helper"
                            fullWidth
                        />
                        <TextInput
                            source="Logistics__from"
                            label="resources.settings.values.logistics__from"
                            fullWidth
                        />
                        <TextInput
                            source="Logistics__fromName"
                            label="resources.settings.values.logistics__from_name"
                            fullWidth
                        />
                        <TextInput
                            source="Cities__DadataAPIToken"
                            label="resources.settings.values.cities__dadata_api_token"
                            fullWidth
                        />
                        <NumberInput
                            source="Cities__resultsLimit"
                            label="resources.settings.values.cities__results_limit"
                            fullWidth
                            min={1}
                            max={1000}
                            step={1}
                        />
                        <TextInput
                            source="Logistics__headerPhone"
                            label="resources.settings.values.logistics__header_phone"
                            fullWidth
                        />
                        <TextInput
                            source="Logistics__marketingSearchHeaderPhone"
                            label="resources.settings.values.logistics__marketing_search_header_phone"
                            fullWidth
                        />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="resources.settings.fieldGroups.general_forms">
                        <TextInput
                            source="Logistics__add_user_to"
                            label="resources.settings.values.logistics__add_user_to"
                            helperText="resources.settings.values.logistics__to__helper"
                            fullWidth
                        />
                        <TextInput
                            source="Reviews__from"
                            label="resources.settings.values.reviews__from"
                            fullWidth
                        />
                        <TextInput
                            source="Reviews__fromName"
                            label="resources.settings.values.reviews__from_name"
                            fullWidth
                        />
                        <TextInput
                            source="Reviews__to"
                            label="resources.settings.values.reviews__to"
                            helperText="resources.settings.values.reviews__to__helper"
                            fullWidth
                        />
                        <TextInput
                            source="Feedback__from"
                            label="resources.settings.values.feedback__from"
                            fullWidth
                        />
                        <TextInput
                            source="Feedback__fromName"
                            label="resources.settings.values.feedback__from_name"
                            fullWidth
                        />
                        <TextInput
                            source="Feedback__to"
                            label="resources.settings.values.feedback__to"
                            helperText="resources.settings.values.feedback__to__helper"
                            fullWidth
                        />
                        <TextInput
                            source="Bids__to"
                            label="resources.settings.values.bids__to"
                            helperText="resources.settings.values.bids__to__helper"
                            fullWidth
                        />
                        <TextInput
                            source="Bids__error_to"
                            label="resources.settings.values.bids__error_to"
                            helperText="resources.settings.values.bids__error_to__helper"
                            fullWidth
                        />
                        <TextInput
                            source="Feedback__quizToEmails"
                            label="resources.settings.values.feedback__quiz_to_emails"
                            helperText="resources.settings.values.feedback__quiz_to_emails__helper"
                            fullWidth
                        />
                        {/*
                        <TextInput
                            source="MessageText__RegistrationMessage"
                            label="resources.settings.values.messagetext__registrationmessage"
                            fullWidth
                            multiline
                            maxRows={'10'}
                        />
                        <TextInput
                            source="MessageText__AfterRegistrationMessage"
                            label="resources.settings.values.messagetext__afterregistrationmessage"
                            fullWidth
                            multiline
                            maxRows={'10'}
                        />
                        */}
                        <TextInput
                            source="MessageText__SuccessFormText"
                            label="resources.settings.values.messagetext__successformtext"
                            fullWidth
                            multiline
                            maxRows={'10'}
                        />
                        <TextInput
                            source="MessageText__PhysicalRegistrationMessage"
                            label="resources.settings.values.messagetext__physicalregistrationmessage"
                            fullWidth
                            multiline
                            maxRows={'10'}
                        />
                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.settings.values.feedback__yandexSmartCaptcha__header'
                            )}
                        </Typography>
                        <TextInput
                            source="Feedback__yandexSmartCaptcha_sitekey"
                            label="resources.settings.values.feedback__yandexSmartCaptcha_sitekey"
                            fullWidth
                        />
                        <TextInput
                            source="Feedback__yandexSmartCaptcha_secret"
                            label="resources.settings.values.feedback__yandexSmartCaptcha_secret"
                            fullWidth
                        />
                    </TabbedForm.Tab>
                    <TabbedForm.Tab label="resources.settings.fieldGroups.general_logging">
                        <Typography variant="h5" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.general_logging__telegram'
                            )}
                        </Typography>
                        <TextInput
                            source="LoggingTelegram__api_token"
                            label="resources.settings.values.logging_telegram__api_token"
                            helperText="resources.settings.values.logging_telegram__api_token__helper"
                            fullWidth
                        />
                        <TextInput
                            source="LoggingTelegram__chat_id"
                            label="resources.settings.values.logging_telegram__chat_id"
                            helperText="resources.settings.values.logging_telegram__chat_id__helper"
                            fullWidth
                        />
                        <Typography variant="h6" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.general_logging__telegram_topics'
                            )}
                        </Typography>
                        <Typography variant="subtitle2" gutterBottom>
                            {translate(
                                'resources.settings.fieldGroups.general_logging__telegram_message_thread_helper'
                            )}
                        </Typography>
                        <TextInput
                            source="LoggingTelegram__calculator_zero_value"
                            label="resources.settings.values.logging_telegram__calculator_zero_value"
                            fullWidth
                        />
                        <TextInput
                            source="LoggingTelegram__calculator_no_transport"
                            label="resources.settings.values.logging_telegram__calculator_no_transport"
                            fullWidth
                        />
                        <TextInput
                            source="LoggingTelegram__calculator_empty_response"
                            label="resources.settings.values.logging_telegram__calculator_empty_response"
                            fullWidth
                        />
                        <TextInput
                            source="LoggingTelegram__calculator_invalid_response"
                            label="resources.settings.values.logging_telegram__calculator_invalid_response"
                            fullWidth
                        />
                        <TextInput
                            source="LoggingTelegram__calculator_no_arrival_plan"
                            label="resources.settings.values.logging_telegram__calculator_no_arrival_plan"
                            fullWidth
                        />
                        <TextInput
                            source="LoggingTelegram__order_zero_value"
                            label="resources.settings.values.logging_telegram__order_zero_value"
                            fullWidth
                        />
                        <TextInput
                            source="LoggingTelegram__order_invalid_response"
                            label="resources.settings.values.logging_telegram__order_invalid_response"
                            fullWidth
                        />
                    </TabbedForm.Tab>
                </TabbedForm>
            </Box>
        </Edit>
    );
};

export default GeneralForm;
